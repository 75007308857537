@import "../../../styles/mediaqueries";
@import "../../../styles/tools";
@import "../../../styles/mixins";

.scrollTriggered {
  position: relative;
  top: 0;
  transition: transform 0.1s linear;

  &.groupMoving {
    position: relative;
    transition: transform 0.1s linear;
    will-change: transform;
  }
}

.stackItem {
  position: sticky;
  top: 80px;
  padding-top: 100px;
  transition: transform 0.4s ease-out, top 0.4s ease-out;
  z-index: 0;
  will-change: transform;
}

.stackItem:last-child {
  position: relative;
  top: 45px !important;
}

.stackItemContent {
  position: relative;
  overflow: hidden;
}

.noGapStackCarousel {
  gap: 0 !important;
  transform: scale(0.95);
  transition: transform 0.4s ease-out;
  @include radius("lg");
  overflow: hidden;
  z-index: 5;
  min-height: pxToRem(600);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.085);

  > div {
    border-radius: 0 !important;
  }
}
