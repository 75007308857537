@import "../../styles/tools";
@import "../../styles/mediaqueries";
@import "../../styles/mixins";

:export {
  iconColor: var(--black);
}

.share {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  padding: var(--space-3);
  @include radius("md");
  background-color: var(--white);
  @include cool-glow(0.5);

  > div {
    display: flex;
    flex-direction: row;
    gap: var(--space-1);
  }

  .qrCode {
    display: block;
    width: pxToRem(120);
    height: pxToRem(120);
    background-color: var(--white);
    margin: 0 auto;
  }
}

.item {
  width: pxToRem(40);
  aspect-ratio: 1;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
  }

  a,
  button {
    display: flex;
    align-content: center;
    justify-content: center;
    border: 2px solid var(--black);
    padding: pxToRem(0);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;

    svg {
      width: var(--space-2);
      transition: 0.3s ease-in-out;
    }

    @include not-touchscreen {
      &:hover {
        background-color: var(--black);

        svg {
          --color: var(--white) !important;
        }
      }
    }
  }
}
